<template>
    <div class="carousel" ref="carousel">
        <div class="inner" ref="carouselContainer">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';


export default {
    props: ['activeIndex'],
    mounted() {
        this.moveToIndex(this.activeIndex);
    },
    methods: {
        moveToIndex(index, duration) {

            if (!this.$refs.carouselContainer) return;

            let point = 0;
            for (let i = 0; i < index; i++) {
                point +=
                    this.$refs.carouselContainer.children[i].clientWidth;
            }

            gsap.to(this.$refs.carouselContainer, {
                x: -point,
                duration: duration,
                overwrite: true,
                ease: 'power2.out',
            });
        },


        getInvertedScale() {
            let currentScale = this.$store.getters.getScaleValue;
            return 1 / currentScale;
        },
    },
    watch: {
        activeIndex(val) {
            this.moveToIndex(val, .5);
        }
    }
}
</script>
<style class="carousel" scoped>
.carousel {
    height: 100%;
    width: 100%;
    position: relative;
    min-height: 0;
}

.inner {
    position: relative;
    /* left: 64px; */
    display: flex;
    height: 100%;
}
</style>
