<template>
    <article class="videoTemplate  main-background" :class="{ 'fullscreenVideo--videopaused': isVideoPaused }">
        <MedrinaDecals class="decal"></MedrinaDecals>
        <video type="video/mp4" crossorigin autoplay controls @playing="isVideoPaused = false"
            @pause="isVideoPaused = true;">
            <source :src="getMediaPath">
        </video>
        <ShareButton :media="properties.video"></ShareButton>

    </article>
</template>

<script>
import ShareButton from '../../ui/ShareButton.vue'

export default {
    props: ['properties', 'slug'],
    components: {
        ShareButton
    },
    data() {
        return {
            isVideoPaused: true
        }
    },
    mounted() {
        console.log(this.properties);
    },
    methods: {
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        }
    },
    computed: {
        getMediaPath() {
            return app.api.Utils.getMediaPath(this.properties.video.src)
        },
    }
}
</script>

<style lang="scss" scoped>
.videoTemplate,
.fullscreenVideo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 150px;
        height: 150px;
        pointer-events: none;
        background-image: url(@/assets/img/ui/play.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        z-index: 3;
    }


    &--videopaused {
        video {
            cursor: pointer;
        }

        &::after,
        &::before {
            content: '';
        }
    }
}

video {
    width: 74%;
}


.decal {
    position: absolute;
    width: 100%;
    z-index: -1;
}
</style>