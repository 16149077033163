<template>
    <transition :name="setTransitionAnimation" v-on:after-leave="transitionAfterLeave"
        v-on:after-enter="transitionAfterEnter">
        <main class="Page" v-if="!animating" :style="{ 'transform-origin': transformOrigin }"
            :class="{ 'Page--dimmed': dimBackground }" @transitionend="transformOrigin = ''">
            <!-- Blur effect -->
            <div class="Page__dimmer" :style="[getBackgroundWidth]"></div>

            <!-- Content Components -->
            <div class="Page__ContentComponents" :style="[getMaximizedSizeAndOffset]">
                <ComponentRender :components="contentComponents" :pageData="getPageData" :activeHotspots="activeHotspots">
                </ComponentRender>
            </div>

            <!-- Scrolling scene -->
            <PageScroller :scrollingData="sceneScrollingData">

                <!-- Hotspot layer -->
                <HotspotRenderer :hotspots="getPageData.children" @hotspotPressed="hotspotPressed"
                    :style="getBackgroundWidth"></HotspotRenderer>

                <!-- Scene Background Components -->
                <div class="Page__BackgroundComponents"
                    :class="{ 'Page__BackgroundComponents--dimmed': hasContentComponentOpen }" :style="getBackgroundWidth">
                    <ComponentRender :components="backgroundComponents" :pageData="getPageData"></ComponentRender>
                </div>

                <!-- Scene Background -->
                <PageBackground :background="getPageData.data.data.background"
                    :hasContentComponentOpen="hasContentComponentOpen" @dblclick="toggleFullscreen"
                    @click="backgroundClicked" :scrollingBackground="getPageData.data.data.enableScrolling">
                </PageBackground>

            </PageScroller>
        </main>
    </transition>
</template>


<script>
import ComponentRender from "../ComponentRender.vue";
import PageBackground from '../page/PageBackground.vue';
import HotspotRenderer from "../hotspots/HotspotRenderer.vue";
import PageScroller from "../page/PageScroller.vue";

export default {
    components: {
        PageScroller,
        HotspotRenderer,
        ComponentRender,
        PageBackground
    },
    data() {
        return {
            backgroundComponents: [],
            contentComponents: [],
            onHomePage: false,
            animating: false,
            transformOrigin: '',
            transitionAnimation: 'changesceneZoom'
        }
    },
    async mounted() {
        this.getBackgroundComponents();
        this.onHomePage = app.api.PageManager.onHomePage();
        this.handleAutoOpenComponents(this.$router.currentRoute._value.fullPath);
        await this.handleComponents(this.$route.fullPath.toLowerCase())
    },
    async beforeRouteUpdate(to, from) {
        var samePage = this.stripQueryStringsFromString(to.fullPath.split(":")[0]) === this.stripQueryStringsFromString(from.fullPath.split(":")[0]);

        if (!samePage) {
            this.getTransitionAnimation(from.fullPath, to.fullPath)
            this.animating = !app.api.Utils.checkIfVideoTransition(from.fullPath, to.fullPath);
        }

        await this.handleComponents(to.fullPath.toLowerCase())

        setTimeout(() => {
            this.backgroundComponents = this.getPageData.components;
            this.$store.commit("setCurrentBackgroundComponents", this.backgroundComponents)
            this.onHomePage = app.api.PageManager.onHomePage();
            if (!samePage)
                this.handleAutoOpenComponents(to.fullPath)
        }, 0);

    },
    methods: {
        getBackgroundComponents() {
            this.getPageData.components.forEach(component => {
                this.backgroundComponents.push(component);
            });
            this.$store.commit("setCurrentBackgroundComponents", this.backgroundComponents)
        },
        transitionAfterLeave() {
            this.animating = false;
            this.transformOrigin = '';
        },
        transitionAfterEnter() {
            this.transformOrigin = '';
            this.transitionAnimation = 'changesceneZoom';
            this.handleAutoOpenComponents(this.$router.currentRoute._value.fullPath);
        },
        hotspotPressed(e) {
            this.transformOrigin = `${e.data.positionData.xPosition}px ${e.data.positionData.yPosition}px`
        },

        // CONTENT COMPONENTS
        async handleComponents(route) {
            let tempRoute = route;
            if (tempRoute.includes(":")) {
                tempRoute = this.removeDublicateComponents(route);
            }

            this.checkRouteAndOpenContentComponents(tempRoute);

            await this.$nextTick()
        },
        checkRouteAndOpenContentComponents(route) {
            // Get all content components from route
            let routeComponentSlugs = route.split(":").slice(1).map(slug => this.stripQueryStringsFromString(slug));

            // Create a Set to store unique components
            let uniqueComponents = new Set();

            // Add new components to the uniqueComponents Set
            routeComponentSlugs.forEach((slug) => {
                let component =
                    app.api.PageManager.getContentComponentInManifest(slug) ||
                    app.api.PageManager.getContentComponent(slug);

                uniqueComponents.add({
                    id: component.id,
                    title: component.title ? component.title : component.name,
                    type: component.data.type ? component.data.type : component.type,
                    properties: component.data.data ? component.data.data : component.data,
                    slug,
                });
            });

            // Remove any duplicates from the existing contentComponents array
            this.contentComponents = this.contentComponents.filter((c) =>
                routeComponentSlugs.includes(c.slug)
            );

            // Add unique components from the Set to the contentComponents array
            uniqueComponents.forEach((c) => {
                if (!this.contentComponents.some((component) => component.slug === c.slug)) {
                    this.contentComponents.push(c);
                    console.log(`Content Component => \nID: ${c.id} - ${c.title}\nType: ${c.type}`);
                }
            });
        },
        removeDublicateComponents(route) {
            // Get all content components from route
            let sceneRoute = route.split(":").slice(0)[0];
            let componentSlugs = route.split(":").slice(1);

            // Remove dublicate component slugs
            componentSlugs = [...new Set(componentSlugs)].join(":");

            return sceneRoute + ":" + componentSlugs;
        },
        stripQueryStringsFromString(string) {
            return string.split('?')[0]
        },
        async handleAutoOpenComponents(route) {
            await this.$nextTick();

            // setTimeout wrapper to make sure hotspots have been update with the necessary variables
            let sceneRoute = route.split(":").slice(0)[0];
            let componentSlugs = route.split(":").slice(1);

            // Check if any components are already open
            if (componentSlugs.length > 0)
                return

            // Find all slugs for hotspots with the boolean autoOpen 
            var autoOpenComponents = app.api.PageManager.getPage(sceneRoute).children.filter(hotspot => hotspot.data.hasOwnProperty("type") && hotspot.data.type.autoOpen === true).map(component => {
                var slug = component.route.split(":").slice(1)[0];
                if (!componentSlugs.includes(slug))
                    return slug;
            }).filter(component => component !== undefined)


            if (autoOpenComponents.length > 0)
                app.api.PageManager.navigateToRouteSilent(sceneRoute + ":" + autoOpenComponents.join(":"))
        },
        toggleFullscreen() {
            app.api.Utils.toggleFullscreen()
        },
        backgroundClicked() {
            if (this.hasContentComponentOpen)
                app.api.PageManager.closeAllContentComponents();
        },
        getTransitionAnimation(from, to) {
            for (let i = 0; i < this.transitionAnimations.length; i++) {
                if ((this.transitionAnimations[i].from == from) && this.transitionAnimations[i].to == to) {
                    this.transitionAnimation = this.transitionAnimations[i].transition
                }
            }
        }
    },
    computed: {
        getPageData() {
            // Get data of the currently open page
            return app.api.PageManager.getPage(this.$route.path) || {};
        },
        hasContentComponentOpen() {
            this.$route;
            let isOpen = this.contentComponents.length > 0;
            this.$store.commit('toggleContentComponentOpen', isOpen);

            return isOpen;
        },
        activeHotspots() {
            // Parse all open hotspots' data to components
            // find matching component slug in this array to get hotspot data for given component. 
            this.$route;
            return this.getPageData.children.filter(hotspot => app.api.PageManager.getCurrentRoute().includes(hotspot.slug))
        },
        dimBackground() {
            if (!this.hasContentComponentOpen) {
                return false
            }

            var dimmingAllowed = true;
            var noDimmingComponents = ["WHITELIST-COMPONENT-NAME-HERE"];
            var openComponents = app.api.PageManager.getOpenContentComponents()
            var i = 0;

            while (i < openComponents.length) {
                if (noDimmingComponents.includes(openComponents[i].type)) {
                    dimmingAllowed = false;
                }
                i++;
            }

            return dimmingAllowed;
        },
        setTransitionAnimation() {
            if (this.$store.getters.canNavigate) {
                return this.transitionAnimation
            } else {
                return 'changesceneInstant'
            }
        },
        transitionAnimations() {
            if (!app.api.PageManager.getCustomComponentFromType('customSceneTransitions'))
                return []

            let transitionAnimations = app.api.PageManager.getCustomComponentFromType('customSceneTransitions').data.transitions;
            let transitions = [];
            transitions = transitionAnimations.map((transition, i) => {
                transition.id = `transition_${i}`;
                transition.from = transition.from ? app.api.PageManager.getRouteFromID(transition.from) : null;
                transition.to = transition.to ? app.api.PageManager.getRouteFromID(transition.to) : null;
                return transition;
            })

            return transitions
        },
        getBackgroundWidth() {
            // Get width of wrapper for background components from the actual width of the background.
            // return {
            //   width: `${this.getPageData.data.data.enableScrolling ? this.$store.getters.getBackgroundWidth : 1920}px`
            // }

            return {
                width: `${this.$store.getters.getBackgroundWidth}px`
            }
        },
        getMaximizedSizeAndOffset() {
            return this.$store.getters.getMaximizedSizeAndOffsetValue;
        },
        sceneScrollingData() {
            return {
                enabled: this.getPageData.data.data.enableScrolling,
                startPosition: this.getPageData.data.data.scrollStartPosition,
                anchorPoints: this.getPageData.data.data.scrollAnchorPoints,
                anchorPointsThreshold: this.getPageData.data.data.anchorPointsThreshold,
                enableDebugging: this.getPageData.data.data.enableDebugging
            }
        }
    },
}
</script>

<style lang="scss">
.Page {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    will-change: transform opacity;

    .Page__dimmer {
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        backdrop-filter: blur(4px);
        transition: opacity 0.2s ease;
    }

    .Page__BackgroundComponents,
    .Page__ContentComponents {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;

        &>* {
            position: absolute;
            pointer-events: all;
        }
    }

    .Page__BackgroundComponents {
        z-index: 2;

        &--dimmed {
            pointer-events: none;

            * {
                pointer-events: none;
            }
        }
    }

    .Page__ContentComponents {
        z-index: 4;
    }

    &--dimmed {
        .Page__dimmer {
            opacity: 1;
        }
    }

    &>* {
        pointer-events: all;
    }
}


/* ++++++++++++++++++++++++ SCENE TRANSITIONS ++++++++++++++++++++++++++ */

/* default scene change animation */
.changesceneZoom-enter-active {
    transition-property: transform, opacity, filter;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

.changesceneZoom-leave-active {
    transition-property: transform, opacity, filter;
    transition-duration: 0.45s;
    transition-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
}

.changesceneZoom-enter-from {
    opacity: 0;
    transform: scale(2);
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.changesceneZoom-leave-to {
    opacity: 0;
    transform: scale(2.5);
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

/* default scene change animation */
.changesceneInstant-enter-active {
    transition-property: opacity;
    transition-duration: 0s;
    transition-timing-function: ease;
}

.changesceneInstant-leave-active {
    transition-property: opacity;
    transition-duration: 0.1s;
    transition-timing-function: ease;
}

.changesceneInstant-enter-from {
    opacity: 0;
}

.changesceneInstant-leave-to {
    opacity: 0;
}

/* Sliding scene change animation */

.changesceneSlideLeft-enter-active,
.changesceneSlideRight-enter-active {
    transition: all 0.4s var(--tk-easeinout);
}

.changesceneSlideLeft-leave-active,
.changesceneSlideRight-leave-active {
    transition: all 0.4s var(--tk-easeinout);
}

//      LEFT
.changesceneSlideLeft-enter-from {
    opacity: 0;
    transform: translateX(-120%);
}

.changesceneSlideLeft-leave-to {
    opacity: 0;
    transform: translateX(120%);
}

//      RIGHT
.changesceneSlideRight-enter-from {
    opacity: 0;
    transform: translateX(120%);
}

.changesceneSlideRight-leave-to {
    opacity: 0;
    transform: translateX(-120%);
}
</style>