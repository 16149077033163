import LocalDataStore from './LocalDataStore';
import PageManager from './PageManager';
import Utils from './Utils';
import Tracking from './Tracking';
import AudioManager from './AudioManager';



export default function ApiManager(app) {
    return Object.freeze({
        initializePreApis,
        initializePostApis
    })


    // Initialize Api functions that has to be run before running the core features such as fetching manifest etc. (Some of these require functions inside the API)
    async function initializePreApis() {
        // Various other helper methods
        bindApiFunctions(Utils, 'Utils', app);
        
        // 
        bindApiFunctions(LocalDataStore, 'LocalDataStore', app);
        
        // Helper methods managing pages and router
        bindApiFunctions(PageManager, 'PageManager', app);

        
        return Promise.resolve()
    }
    

    // Initialize Api functions that DOES NOT need to be run before running the core features such as fetching manifest etc. (If api is not crucial for running the core features)
    async function initializePostApis() {
        // Tracking manager
        bindApiFunctions(Tracking, 'Tracking', app);

        // Sound manager
        bindApiFunctions(AudioManager, 'AudioManager', app);


        return Promise.resolve()
    }
}


// Function to bind methods of an API class instance to an `api` object and freeze it
// ALL API NAMES MUST BE UNIQUE
function bindApiFunctions(apiClass, apiObjectName, app) {
    const api = {};
    const apiInstance = new apiClass(app);

    // Bind methods to the `api` object
    Object.getOwnPropertyNames(apiClass.prototype).forEach(property => {
        if (typeof apiInstance[property] === 'function' && property !== 'constructor') {
            api[property] = apiInstance[property].bind(apiInstance);
        }
    });

    // Freeze the `api` object to prevent modifications
    app.api[apiObjectName] = Object.freeze(api);
}