<template>
    <router-link :to="homeURL" id="logo">
        <img src="@/assets/img/ui/logo.svg">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss" scoped>
#logo {
    position: absolute;
    width: 250px;
    top: 32px;
    left: 40px;
    z-index: 2;
    cursor: pointer;

    &>img {
        width: 100%;
    }
}
</style>