<template>
    <AppLoaderVue v-if="!ready"></AppLoaderVue>
    <AppContainer v-else></AppContainer>
</template>

<script>
import AppLoaderVue from './components/core/AppLoader.vue';
import AppContainer from './components/core/AppContainer.vue';

export default {
    components: {
        AppLoaderVue,
        AppContainer,
    },
    created() {
        window.app.vm = this;
    },
    computed: {
        ready() {
            return this.$store.getters.ready;
        },
    },
};
</script>

<style lang="scss">
html,
body {
    height: 100vh;
    max-width: 100vw;
    scroll-behavior: smooth;
    background-color: var(--dark-grey);
    font-size: 18px;
    overflow: hidden;

    /* Disable zooming for all devices */
    touch-action: pan-x pan-y !important;
    user-scalable: none !important;
    -ms-touch-action: pan-x pan-y !important;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

img,
button,
a {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

// Global Styling 
* {
    backface-visibility: hidden;
    touch-action: manipulation;

    // Scrollbar
    // scrollbar-color: white rgba($color: #fff, $alpha: .2);
    // scrollbar-width: thin;

    & *::-webkit-scrollbar {
        width: 14px;
    }

    & *::-webkit-scrollbar-track {
        background: rgba($color: #fff, $alpha: .2);
        border-radius: 10px;
    }

    & *::-webkit-scrollbar-thumb {
        background: white;
        height: 50%;
        border-radius: 10px;

    }
}

p {
    display: block;
    margin-bottom: 1em;

    >a {
        display: inline;
        color: var(--secondary-color)
    }
}

strong,
b {
    font-weight: 900;
}

em {
    font-style: italic;
}

ul,
ol {
    list-style-position: outside;
    // padding-left: 30px;
    margin-bottom: 2.5em;

    font-style: italic;
    font-family: 'OpenSans';


    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
        font-style: italic;
        font-family: 'OpenSans';
        margin-bottom: 4px;

        padding-left: 1em;
        text-indent: -1.1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    li:before {
        content: '"';
        padding-right: .5em;
        color: var(--tertiary-color);
        font-size: 1.3em;
    }
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

button {
    cursor: pointer;

    &--disabled {
        cursor: default;
        pointer-events: none;
    }
}

h2 {
    color: var(--tertiary-color);
    font-size: 52px;
    font-weight: bold;
}
</style>