import { createRouter, createWebHashHistory } from "vue-router";
import Page from "../components/core/page/Page.vue";
import store from "../store/store";

const routes = [{ path: "/:id*", component: Page }];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// Router navigation guard
router.beforeEach((to, from, next) => {
    // Allow the navigation
    if (store.getters.canNavigate) {
        // will reset the logo showing when changing page or content
        store.dispatch("showLogo");

        if (!hasQueryParameters(to) && hasQueryParameters(from)) {
            store.commit("setQueryParameters", from.query);
            next({ ...to, query: from.query });
        } else {
            store.commit("setQueryParameters", to.query);
            next();
        }
        console.log("from rrouter navigating");
        // Send events to Tracking API
        app.api.Tracking.navigation("navigation", to.fullPath, "");
        app.api.AudioManager.checkIfAudio(to.fullPath);
    } else {
        // Cancel the navigation
        // You can redirect to a specific route or show a loading screen until the navigation is allowed
        next(false);
    }
});

// Look up if route has any query parameters
function hasQueryParameters(route) {
    return !!Object.keys(route.query).length;
}

export default router;
