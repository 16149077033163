<template>
    <article class="imageAndTextTemplate main-background">
        <div class="img-container">
            <img :src="getMediaPath">
        </div>
        <div class="text-container">
            <h2>{{ properties?.title }}</h2>
            <div class="scroll-container" v-html="properties?.description"></div>
        </div>
        <ShareButton :media="properties.image"></ShareButton>

    </article>
</template>
<script>
import ShareButton from '../../ui/ShareButton.vue'

export default {
    props: ['properties', 'slug'],
    components: {
        ShareButton
    },
    mounted() {
        console.log(this.properties);
    },
    methods: {
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        }
    },
    computed: {
        getMediaPath() {
            return app.api.Utils.getMediaPath(this.properties?.image?.src)
        },
    }
}
</script>


<style lang="scss" scoped>
.imageAndTextTemplate {
    height: 100%;
    display: grid;

    grid-template-columns: 50% 50%;
}

.img-container {
    overflow: hidden;
}

img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.text-container {
    background-color: rgba($color: #000000, $alpha: .2);

    color: white;
    font-size: 32px;

    text-align: start;
    padding: 60px 40px;

    overflow: hidden;
}

.scroll-container {
    height: 75%;

    overflow-y: auto;
    padding-right: 34px;
}

h2 {
    margin-bottom: 22px;
}
</style>