<template>
    <button id="ShareButton" @click="shareClicked">
        <div class="button">
            <img src="@/assets/img/ui/mail.svg">
        </div>
        <span>Share</span>
    </button>
</template>
<script>
import config from '@/config';

export default {
    methods: {
        shareClicked() {
            this.openMail();
        },
        openMail() {
            window.open(this.composedEmail, '_self');
        },
        getMediaPath(item) {
            return app.api.Utils.getMediaPath(item.src)
        },
    },
    props: ['media'],
    data() {
        return {
            subject: 'Look at this page from Medrina',
            newLineChar: '%0D%0A',
            generalBody: `Take a look:`
        }
    },
    computed: {
        composedEmail() {
            let mediaString = '';

            if (Array.isArray(this.media)) {
                this.media.forEach(element =>
                    mediaString += config.rootUrl + element.src + this.newLineChar
                );
            } else {
                mediaString += config.rootUrl + this.media.src + this.newLineChar
            }

            let body = this.generalBody + this.newLineChar + mediaString;
            return `mailto:?subject=${this.subject}&body=${body}`;
        }
    }
}
</script>
<style lang="scss" scoped>
$hotspot-height: 44px;

#ShareButton {
    position: absolute;
    bottom: 32px;
    left: 200px;
    display: flex;
    align-items: center;
    z-index: 1;



    height: 50px;
    // width: 50px;
    transition-property: opacity, transform;
    transition-duration: 0.1s;
    transition-timing-function: ease;

    .button {
        width: 100%;
        z-index: 3;
        display: flex;

        img {
            width: $hotspot-height;
        }
    }

    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        height: $hotspot-height - 6px;
        background: var(--light-grey);
        padding: 0 18px 0 26px;
        margin-left: -14px;
        z-index: 2;
        font-weight: bold;
        color: var(--main-color);
        border-radius: 0 $hotspot-height $hotspot-height 0;
    }

    &:hover {
        transform: scale(1.05);
    }
}

.ShareButton--inactive {
    opacity: 0;
    pointer-events: none !important;
}
</style>