<template>
    <router-link class="ListHotspot" :to="hotspot.route" @click="hotspotPressed">
        <span v-html="getHotspotTitle"></span>
    </router-link>
</template>

<script>
export default {
    props: ['hotspot'],
    methods: {
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        }
    }
}
</script>

<style lang="scss">
.ListHotspot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    border: 2px solid #fff;
    color: #fff;
    font-size: 20px;
    margin-right: 15px;
    border-radius: 3px;
    padding: 0 35px;
    cursor: pointer;
    transition: background 0.15s ease;

    &.router-link-active {
        background: var(--main-color);
        border: 2px solid var(--main-color);
    }

    &:last-of-type {
        margin-right: 0;
    }
}
</style>