<template>
    <article class="LoginScreen">
        <img :src="getMediaPath(globalData?.data?.loginBackground?.src)" class="bg-img">
        <div class="container">
            <img class="logo" alt="medrina logo" src="@/assets/img/ui/logo.svg">
            <h2>{{ globalData?.data?.tagline }}</h2>
            <div class="password-form">
                <h3>Use your access code to sign in</h3>
                <input class="login-input" type="password" ref="loginKey" name="loginKey" id="loginKey"
                    placeholder="Enter access code" @click="focus" @keyup.enter="validate">
                <p class="error-text" v-show="showErrorText">Incorrect code, please try again</p>
                <button class="login-btn" type="submit" value="Login" @click="validate">Sign in</button>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    data() {
        return {
            globalData: undefined,
            showErrorText: false,
        };
    },
    mounted() {
        this.globalData = app.api.PageManager.getCustomComponentFromType('globalData');
        if (this.globalData.data.password === '') {
            this.login();
            return;
        }
    },
    methods: {
        getMediaPath(src) {
            return app.api.Utils.getMediaPath(src)
        },
        async validatePassword(message, keymatch) {
            // encode as UTF-8 
            const msgBuffer = new TextEncoder().encode(message); // hash the message 
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer); // convert ArrayBuffer to Array 
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert bytes to hex string 
            const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
            return hashHex == keymatch;
        },
        validate() {
            const input = this.$refs.loginKey.value;
            const password = this.globalData.data.password;

            this.validatePassword(input, password).then((res) => {
                if (res === true) {
                    this.login();
                } else {
                    this.$refs.loginKey.focus();
                    this.showErrorText = true;
                }
            })
        },
        login() {
            localStorage.setItem('isLoggedIn', 'true-do-not-guess');
            this.$store.commit('setLoggedIn', true);

        }
    }
}
</script>

<style lang="scss" scoped>
.LoginScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    justify-content: center;

    overflow: hidden;
}

.bg-img {
    position: absolute;
    width: 101%;
    height: 101%;
    top: -3px;
    left: -3px;
    filter: blur(3px);
}

.logo {

    width: 55%;
    background-color: white;
    border-radius: 75px;
}

.container {
    position: relative;
    height: 100%;
    width: 62%;
    z-index: 0;

    gap: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: var(--main-color);
        opacity: .66;
        z-index: -1;
    }
}

h2 {
    color: white;
    font-size: 60px;
}

h3 {
    color: var(--main-color);
    font-size: 28px;
    font-weight: bold;

    margin-bottom: 30px;
}

.password-form {
    background-color: white;
    width: 40%;
    padding: 50px 60px;

    border-radius: 10px;
}

.login-input {
    width: 100%;
    height: 60px;
    background-color: #FBFBFD;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
}

.login-btn {
    margin-top: 35px;
    display: block;
    text-align: center;
    width: 100%;
    background-color: var(--secondary-color);
    color: white;

    font-size: 20px;
    font-weight: 500;
    height: 50px;
    border-radius: 10px;

    &:hover {
        filter: brightness(1.2);
    }

    &:active {
        transform: scale(.98);
    }
}

.error-text {
    color: var(--secondary-color);
    font-size: 14px;
    position: absolute;
    // text-align: center;
    left: 50%;
    margin-top: 2px;
    transform: translateX(-50%);
}
</style>