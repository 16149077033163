export default function Preloader(app) {
    let backgrounds = new Set();
    let delayedBackgrounds = new Set();
    let necessaryAssets = [];
    let backgroundAssets = [];
    
    return Object.freeze({
        preLoadBackgrounds,
        preloadAssets
    })


    async function preLoadBackgrounds() {
        // Run preloader for all scene backgrounds
        getBackgroundAssets();

        await preloadBackgroundSrc();
        console.log("Preload necessary background assets for\n => ", backgrounds)

        preloadBackgroundAssets(delayedBackgrounds)
        console.log("Starting preload for rest of background assets \n => ", delayedBackgrounds)
        return Promise.resolve();
    }

    function getBackgroundAssets() {
        // find all assets in scene backgrounds
        // BOTH images and videos.
        let pagesToPreload = [];
        let pagesToPreloadBackground = [];
        let allPages = app.api.PageManager.getAllPages().filter(page => page.background !== null);
        let currentRoute = app.api.PageManager.getCurrentRoute();
        let currentPage = allPages.find(page => page.route === currentRoute);
        
        // Fallback if no currentPage because currentRoute does not exist.
        if (!currentPage)
            currentPage = app.api.PageManager.getRootPage();

        // Current Page
        pagesToPreload.push(currentPage)

        // Parent Page
        if(currentPage.parent)
            pagesToPreload.push(allPages.find(page => page.slug === currentPage.parent));
        
        // Children Pages
        currentPage.children.filter(child => child.background !== null).forEach(page => pagesToPreload.push(page))

        // All other pages for background preload
        allPages.forEach(page => {
            if (!pagesToPreload.includes(page) && page !== currentPage && page.parent !== currentPage.slug && !currentPage.children.includes(page))
                pagesToPreloadBackground.push(page);
        });
        
        pagesToPreload.forEach(bg => {
            if (!bg || !bg.background)
                return

            backgrounds.add(findSrc(Object.entries(bg.background)))            
        });

        pagesToPreloadBackground.forEach(bg => {
            if (!bg || !bg.background)
                return

            delayedBackgrounds.add(findSrc(Object.entries(bg.background)))            
        });
    }


    function findSrc(arr) {
        // loop through each element of the array
        for (const el of arr) {
            // if the current element is an array
            if (Array.isArray(el)) {
                // recursively call the findSrc function with the current element as argument
                const result = findSrc(el);
                // if the result is not null, return it
                if (result) {
                    return result;
                }
            }

            // if the current element is an object with a src property
            else if (el && typeof el === 'object' && 'src' in el) {
                // return the value of the src property
                return el.src;
            }
        }
        // return null if no src property is found
        return null;
    }

    async function preloadBackgroundSrc() {
        const promises =  Array.from(backgrounds).map((src) => {
          let fileType = app.api.Utils.getMediaType(src);
      
          if (fileType === 'video') {
            return app.api.Utils.preloadVideo(src);
          } else if (fileType === 'image') {
            app.api.Utils.preloadImage(src);
            return Promise.resolve();
          }
        });
      
        await Promise.all(promises);
    }
    
    
    async function preloadAssets() {
        if(!app.api.PageManager.getCustomComponentFromType("assetPreloader")) {
            console.log("No custom assets for preload")
            return Promise.resolve();
        }
        
        // preload background assets async with no callback (assets not necessary for initial load)
        let tempBackgroundAssets = app.api.PageManager.getCustomComponentFromType("assetPreloader").data.backgroundAssets;
        tempBackgroundAssets.forEach(asset => {
            backgroundAssets.push(findSrc(Object.entries(asset)))
        });
        preloadBackgroundAssets(backgroundAssets)
        
        // preload background assets async with callback (assets necessary for initial load)
        let tempNecessaryAssets = app.api.PageManager.getCustomComponentFromType("assetPreloader").data.necessaryAssets;
        tempNecessaryAssets.forEach(asset => {
            necessaryAssets.push(findSrc(Object.entries(asset)))
        });
        await preloadNecessaryAssets(necessaryAssets)
        
        return Promise.resolve();
    }
    
    
    async function preloadNecessaryAssets(arr) {
        const promises = arr.map((src) => {
            let fileType = app.api.Utils.getMediaType(src);
            
            if (fileType === 'video') {
                return app.api.Utils.preloadVideo(src);
            } else if (fileType === 'image') {
                app.api.Utils.preloadImage(src);
                return Promise.resolve();
            } else if (fileType === 'audio') {
                app.api.Utils.preloadAudio(src);
            }
        });

        await Promise.all(promises);
    }


    async function preloadBackgroundAssets(arr) {
        arr.forEach((src) => {
            let fileType = app.api.Utils.getMediaType(src);
            
            if (fileType === 'video') {
                return app.api.Utils.preloadVideo(src);
            } else if (fileType === 'image') {
                app.api.Utils.preloadImage(src);
                return Promise.resolve();
            } else if (fileType === 'audio') {
                app.api.Utils.preloadAudio(src);
            }
        });
    }
}