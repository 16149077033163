<template>
    <article class="pdfViewerTemplate">
        <iframe v-show="isFullScreen" class="pdfContainer" ref="pdf" :src="getMediaPath(properties.pdf?.src)"
            :style="invertedScaleStyles" @click.stop></iframe>
        <div v-show="!isFullScreen" class="preview">
            <div class="media">
                <img v-if="properties.image" :src="getMediaPath(properties.image?.src)" :alt="properties.image?.name">
            </div>
            <div class="text-container">
                <h2>{{ properties?.title }}</h2>
                <div class="scroll-container" v-html="properties?.description"></div>
                <button class="Hotspot" @click="onFullScreenClick()">
                    <div class="Hotspot__button">
                    </div>

                    <span>Open PDF</span>
                </button>
            </div>
            <button class="fullscreenVideo__close" @click="closeComponent"></button>

        </div>
        <ShareButton :media="properties.pdf"></ShareButton>

    </article>
</template>

<script>
import ShareButton from '../../ui/ShareButton.vue'

export default {
    props: ['properties', 'slug'],
    data() {
        return {
            isFullScreen: this.properties.image ? false : true,
        }
    },
    components: {
        ShareButton
    },
    methods: {
        closeComponent() {
            this.$store.dispatch('showLogo');
            this.$emit("closeComponent", this.slug)
        },
        getMediaPath(src) {
            return app.api.Utils.getMediaPath(src)
        },
        onFullScreenClick() {
            this.$store.dispatch('hideLogo');
            this.isFullScreen = true
        }
    },
    computed: {
        invertedScaleStyles() {
            // lets iframe content scale to always be 1! - despite any TK scale
            var currentScale = this.$store.getters.getScaleValue;
            var invertedScale = 1 / currentScale;
            var initScale = 100;

            return {
                'transform': `scale(${invertedScale})`,
                'height': `${currentScale * initScale}%`,
                'width': `${currentScale * 100}%`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pdfViewerTemplate {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    //  width: 1280px;
    //  height: 720px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .6);
}

.pdfContainer {
    position: absolute;
    z-index: 1;
}

.preview {
    position: relative;
    background: var(--blue-gradient);
    width: 80%;
    height: 76%;
    display: grid;
    grid-template-columns: 50% 50%;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--tertiary-color);
        z-index: -1;
    }
}

.media {
    overflow: hidden;
}

img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

h2 {
    margin-bottom: 35px;
    font-size: 45px;
}

.text-container {
    color: white;
    font-size: 22px;

    text-align: start;
    padding: 40px 50px;

    overflow: hidden;

    &>button {
        margin-top: 40px;

        &>span {
            background: var(--light-grey);
            color: var(--main-color);
            font-weight: bold;
        }

        transition-property: opacity,
        transform;
        transition-duration: 0.1s;
        transition-timing-function: ease;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.scroll-container {
    height: 75%;

    overflow-y: auto;
    padding-right: 34px;
}



.Hotspot__button {
    background-image: url('@/assets/img/ui/openFile.svg') !important;
}

.fullscreenVideo__close {
    position: absolute;
    left: 100%;
    top: 0;
    height: 60px;
    width: 60px;
    background: var(--main-color);
    background-image: url(@/assets/img/ui/close.svg);
    background-position: center;
    background-repeat: no-repeat;
}
</style>