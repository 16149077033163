<template>
  <article id="AppLoader">
        <span id="AppLoader-Percentage">0%</span>
        <h6 id="AppLoader-Text">Loading</h6>
  </article>
</template>

<style lang="scss">
#AppLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    z-index: 100;
    color: var(--light-grey);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
        position: absolute;
        margin-top: -76px;
        font-size: 32px;
    }

    h6 {
        font-size: 28px;
    }

    &::before {
        content:'';
        border: 3px solid var(--light-grey);
        border-top: 3px solid var(--secondary-color);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s ease-in-out infinite;

        margin-bottom: 40px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>