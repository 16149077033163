<template>
    <button @click="mute" id="MuteButton" :class="{'MuteButton--inactive': contentComponentOpen}">
        <img src="@/assets/img/ui/volume-none.svg" v-if="isMuted">
        <img src="@/assets/img/ui/volume-loud.svg" v-else>
    </button>
</template>
<script>
export default {
    methods: {
        mute() {
            this.$store.commit('toggleMute');
        }
    },
    computed: {
        isMuted() {
            return this.$store.getters.getIsMuted;
        },
        contentComponentOpen(){
            return this.$store.getters.getContentComponentOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
    #MuteButton {
        left: 110px;
    }

    .MuteButton--inactive {
        opacity: 0.5;
        pointer-events: none !important;

        *{
            pointer-events: none !important;
        }
    }
</style>