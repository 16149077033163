<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 438.297">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_19" data-name="Rectangle 19" width="1200" height="438.297"
                    transform="translate(219.045)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-2">
                <rect id="Rectangle_18" data-name="Rectangle 18" width="1638.091" height="438.297" fill="none" />
            </clipPath>
            <clipPath id="clip-path-4">
                <rect id="Rectangle_16" data-name="Rectangle 16" width="1636.142" height="436.35" fill="none" />
            </clipPath>
        </defs>
        <g id="medrina_Decal" transform="translate(-219.045)">
            <g id="Group_14" data-name="Group 14" clip-path="url(#clip-path)">
                <g id="Group_13" data-name="Group 13">
                    <g id="Group_12" data-name="Group 12" clip-path="url(#clip-path-2)">
                        <g id="Group_11" data-name="Group 11" opacity="0.3">
                            <g id="Group_10" data-name="Group 10">
                                <g id="Group_9" data-name="Group 9" clip-path="url(#clip-path-2)">
                                    <g id="Group_8" data-name="Group 8" transform="translate(0.974 0.974)">
                                        <g id="Group_7" data-name="Group 7" clip-path="url(#clip-path-4)">
                                            <path id="Path_9" data-name="Path 9"
                                                d="M1,446.122H110.088V337.034A109.087,109.087,0,0,0,1,446.122"
                                                transform="translate(-1 -9.772)" fill="#f0004f" />
                                            <path id="Path_10" data-name="Path 10"
                                                d="M1,225.023H110.088V334.111A109.087,109.087,0,0,1,1,225.023"
                                                transform="translate(-1 -6.848)" fill="#06c4f1" />
                                            <path id="Path_11" data-name="Path 11"
                                                d="M113.012,446.122V337.034H222.1A109.087,109.087,0,0,1,113.012,446.122"
                                                transform="translate(-3.924 -9.772)" fill="#06c4f1" />
                                            <path id="Path_12" data-name="Path 12"
                                                d="M222.1,334.11H113.011V225.022A109.087,109.087,0,0,1,222.1,334.11"
                                                transform="translate(-3.924 -6.848)" fill="#f0004f" />
                                            <path id="Path_13" data-name="Path 13"
                                                d="M1,1V110.087H110.088A109.087,109.087,0,0,0,1,1"
                                                transform="translate(-1 -1)" fill="#06c4f1" />
                                            <path id="Path_14" data-name="Path 14"
                                                d="M222.1,1V110.087H113.011A109.087,109.087,0,0,1,222.1,1"
                                                transform="translate(-3.924 -1)" fill="#f0004f" />
                                            <path id="Path_15" data-name="Path 15"
                                                d="M558.135,1V110.087H449.047A109.087,109.087,0,0,1,558.135,1"
                                                transform="translate(-12.696 -1)" fill="#f0004f" />
                                            <path id="Path_16" data-name="Path 16"
                                                d="M1,113.011H110.088V222.1A109.087,109.087,0,0,1,1,113.011"
                                                transform="translate(-1 -3.924)" fill="#f0004f" />
                                            <path id="Path_17" data-name="Path 17"
                                                d="M113.012,222.1V113.011H222.1A109.087,109.087,0,0,1,113.012,222.1"
                                                transform="translate(-3.924 -3.924)" fill="#06c4f1" />
                                            <path id="Path_18" data-name="Path 18"
                                                d="M561.058,222.1H670.146V113.011A109.087,109.087,0,0,0,561.058,222.1"
                                                transform="translate(-15.621 -3.924)" fill="#f0004f" />
                                            <path id="Path_19" data-name="Path 19"
                                                d="M561.058,1H670.146V110.087A109.087,109.087,0,0,1,561.058,1"
                                                transform="translate(-15.621 -1)" fill="#06c4f1" />
                                            <path id="Path_20" data-name="Path 20"
                                                d="M673.07,222.1V113.011H782.158A109.087,109.087,0,0,1,673.07,222.1"
                                                transform="translate(-18.545 -3.924)" fill="#06c4f1" />
                                            <path id="Path_21" data-name="Path 21"
                                                d="M782.158,110.087H673.07V1A109.087,109.087,0,0,1,782.158,110.087"
                                                transform="translate(-18.545 -1)" fill="#f0004f" />
                                            <path id="Path_22" data-name="Path 22"
                                                d="M673.07,446.122V337.034H782.158A109.087,109.087,0,0,1,673.07,446.122"
                                                transform="translate(-18.545 -9.772)" fill="#06c4f1" />
                                            <path id="Path_23" data-name="Path 23"
                                                d="M561.058,446.122V337.034H670.146A109.087,109.087,0,0,1,561.058,446.122"
                                                transform="translate(-15.621 -9.772)" fill="#f0004f" />
                                            <path id="Path_24" data-name="Path 24"
                                                d="M782.158,334.11H673.07V225.022A109.087,109.087,0,0,1,782.158,334.11"
                                                transform="translate(-18.545 -6.848)" fill="#f0004f" />
                                            <path id="Path_25" data-name="Path 25"
                                                d="M670.146,225.023V334.111H561.058A109.087,109.087,0,0,1,670.146,225.023"
                                                transform="translate(-15.621 -6.848)" fill="#06c4f1" />
                                            <path id="Path_26" data-name="Path 26"
                                                d="M1342.216,391.578a54.544,54.544,0,1,0-54.543,54.543,54.543,54.543,0,0,0,54.543-54.543"
                                                transform="translate(-33.165 -9.772)" fill="#f0004f" />
                                            <path id="Path_27" data-name="Path 27"
                                                d="M1678.253,167.555A54.544,54.544,0,1,0,1623.71,222.1a54.543,54.543,0,0,0,54.543-54.543"
                                                transform="translate(-41.937 -3.924)" fill="#06c4f1" />
                                            <path id="Path_28" data-name="Path 28"
                                                d="M558.134,279.567a54.544,54.544,0,1,1-54.543-54.543,54.543,54.543,0,0,1,54.543,54.543"
                                                transform="translate(-12.696 -6.848)" fill="#f0004f" />
                                            <path id="Path_29" data-name="Path 29"
                                                d="M334.111,55.543A54.544,54.544,0,1,1,279.568,1a54.543,54.543,0,0,1,54.543,54.543"
                                                transform="translate(-6.848 -1)" fill="#06c4f1" />
                                            <path id="Path_30" data-name="Path 30"
                                                d="M225.023,113.011A109.088,109.088,0,0,0,334.111,222.1V113.011Z"
                                                transform="translate(-6.848 -3.924)" fill="#f0004f" />
                                            <path id="Path_31" data-name="Path 31"
                                                d="M334.111,446.122A109.088,109.088,0,0,0,225.023,337.034V446.122Z"
                                                transform="translate(-6.848 -9.772)" fill="#f0004f" />
                                            <path id="Path_32" data-name="Path 32"
                                                d="M334.111,225.023A109.088,109.088,0,0,1,225.023,334.111V225.023Z"
                                                transform="translate(-6.848 -6.848)" fill="#06c4f1" />
                                            <path id="Path_33" data-name="Path 33"
                                                d="M449.047,446.122A109.088,109.088,0,0,1,558.135,337.034V446.122Z"
                                                transform="translate(-12.696 -9.772)" fill="#06c4f1" />
                                            <path id="Path_34" data-name="Path 34"
                                                d="M337.035,1h0V110.087H446.123A109.088,109.088,0,0,0,337.035,1"
                                                transform="translate(-9.772 -1)" fill="#f0004f" />
                                            <path id="Path_35" data-name="Path 35"
                                                d="M449.047,113.011h0V222.1H558.135A109.088,109.088,0,0,0,449.047,113.011"
                                                transform="translate(-12.696 -3.924)" fill="#06c4f1" />
                                            <path id="Path_36" data-name="Path 36"
                                                d="M446.123,337.034h0V446.122H337.035A109.088,109.088,0,0,1,446.123,337.034"
                                                transform="translate(-9.772 -9.772)" fill="#f0004f" />
                                            <path id="Path_37" data-name="Path 37"
                                                d="M446.123,225.023H337.035A109.087,109.087,0,0,0,446.123,334.111Z"
                                                transform="translate(-9.772 -6.848)" fill="#06c4f1" />
                                            <path id="Path_38" data-name="Path 38"
                                                d="M897.094,1h109.088V110.087A109.087,109.087,0,0,1,897.094,1"
                                                transform="translate(-24.393 -1)" fill="#f0004f" />
                                            <path id="Path_39" data-name="Path 39"
                                                d="M785.082,222.1H894.17V113.011A109.087,109.087,0,0,0,785.082,222.1"
                                                transform="translate(-21.469 -3.924)" fill="#f0004f" />
                                            <path id="Path_40" data-name="Path 40"
                                                d="M1009.106,1V110.087h109.087A109.086,109.086,0,0,0,1009.106,1"
                                                transform="translate(-27.317 -1)" fill="#06c4f1" />
                                            <path id="Path_41" data-name="Path 41"
                                                d="M897.094,113.011V222.1h109.088A109.087,109.087,0,0,0,897.094,113.011"
                                                transform="translate(-24.393 -3.924)" fill="#06c4f1" />
                                            <path id="Path_42" data-name="Path 42"
                                                d="M1118.193,113.011H1009.1V222.1a109.087,109.087,0,0,0,109.088-109.088"
                                                transform="translate(-27.317 -3.924)" fill="#f0004f" />
                                            <path id="Path_43" data-name="Path 43"
                                                d="M894.17,446.122V337.034H785.082A109.087,109.087,0,0,0,894.17,446.122"
                                                transform="translate(-21.469 -9.772)" fill="#f0004f" />
                                            <path id="Path_44" data-name="Path 44"
                                                d="M1118.193,446.122V337.034H1009.1a109.087,109.087,0,0,0,109.088,109.088"
                                                transform="translate(-27.317 -9.772)" fill="#f0004f" />
                                            <path id="Path_45" data-name="Path 45"
                                                d="M1121.117,446.122V337.034H1230.2a109.087,109.087,0,0,1-109.088,109.088"
                                                transform="translate(-30.241 -9.772)" fill="#06c4f1" />
                                            <path id="Path_46" data-name="Path 46"
                                                d="M897.094,446.122V337.034h109.088A109.087,109.087,0,0,1,897.094,446.122"
                                                transform="translate(-24.393 -9.772)" fill="#06c4f1" />
                                            <path id="Path_47" data-name="Path 47"
                                                d="M1454.229,446.122V337.034H1345.141a109.087,109.087,0,0,0,109.088,109.088"
                                                transform="translate(-36.089 -9.772)" fill="#06c4f1" />
                                            <path id="Path_48" data-name="Path 48"
                                                d="M1006.182,225.023H897.094V334.111a109.088,109.088,0,0,0,109.088-109.088"
                                                transform="translate(-24.393 -6.848)" fill="#f0004f" />
                                            <path id="Path_49" data-name="Path 49"
                                                d="M1009.106,225.023V334.111h109.087a109.086,109.086,0,0,0-109.087-109.088"
                                                transform="translate(-27.317 -6.848)" fill="#06c4f1" />
                                            <path id="Path_50" data-name="Path 50"
                                                d="M1678.252,225.023V334.111H1569.164a109.087,109.087,0,0,1,109.088-109.088"
                                                transform="translate(-41.937 -6.848)" fill="#f0004f" />
                                            <path id="Path_51" data-name="Path 51"
                                                d="M1457.152,225.023V334.111H1566.24a109.087,109.087,0,0,0-109.088-109.088"
                                                transform="translate(-39.013 -6.848)" fill="#06c4f1" />
                                            <path id="Path_52" data-name="Path 52"
                                                d="M1678.252,337.034H1569.164V446.122a109.087,109.087,0,0,0,109.088-109.088"
                                                transform="translate(-41.937 -9.772)" fill="#06c4f1" />
                                            <path id="Path_53" data-name="Path 53"
                                                d="M1566.24,446.122V337.034H1457.152A109.087,109.087,0,0,0,1566.24,446.122"
                                                transform="translate(-39.013 -9.772)" fill="#f0004f" />
                                            <path id="Path_54" data-name="Path 54"
                                                d="M1454.228,1V110.087H1345.14A109.087,109.087,0,0,1,1454.228,1"
                                                transform="translate(-36.089 -1)" fill="#f0004f" />
                                            <path id="Path_55" data-name="Path 55"
                                                d="M1233.129,1V110.087h109.088A109.087,109.087,0,0,0,1233.129,1"
                                                transform="translate(-33.165 -1)" fill="#06c4f1" />
                                            <path id="Path_56" data-name="Path 56"
                                                d="M1454.228,113.011H1345.14V222.1a109.087,109.087,0,0,0,109.088-109.088"
                                                transform="translate(-36.089 -3.924)" fill="#06c4f1" />
                                            <path id="Path_57" data-name="Path 57"
                                                d="M1342.217,222.1V113.011H1233.129A109.087,109.087,0,0,0,1342.217,222.1"
                                                transform="translate(-33.165 -3.924)" fill="#f0004f" />
                                            <path id="Path_58" data-name="Path 58"
                                                d="M1121.117,334.11A109.089,109.089,0,0,1,1230.2,225.022V334.11Z"
                                                transform="translate(-30.241 -6.848)" fill="#f0004f" />
                                            <path id="Path_59" data-name="Path 59"
                                                d="M1230.2,222.1a109.088,109.088,0,0,0-109.088-109.088V222.1Z"
                                                transform="translate(-30.241 -3.924)" fill="#06c4f1" />
                                            <path id="Path_60" data-name="Path 60"
                                                d="M1121.117,1A109.088,109.088,0,0,0,1230.2,110.087V1Z"
                                                transform="translate(-30.241 -1)" fill="#f0004f" />
                                            <path id="Path_61" data-name="Path 61"
                                                d="M1457.153,1a109.088,109.088,0,0,0,109.088,109.088V1Z"
                                                transform="translate(-39.013 -1)" fill="#06c4f1" />
                                            <path id="Path_62" data-name="Path 62"
                                                d="M1345.141,334.11h0V225.022h109.088A109.088,109.088,0,0,1,1345.141,334.11"
                                                transform="translate(-36.089 -6.848)" fill="#f0004f" />
                                            <path id="Path_63" data-name="Path 63"
                                                d="M1569.165,110.087h0V1h109.088a109.088,109.088,0,0,1-109.088,109.088"
                                                transform="translate(-41.937 -1)" fill="#f0004f" />
                                            <path id="Path_64" data-name="Path 64"
                                                d="M1566.241,222.1H1457.153a109.087,109.087,0,0,1,109.088-109.088Z"
                                                transform="translate(-39.013 -3.924)" fill="#f0004f" />
                                            <rect id="Rectangle_15" data-name="Rectangle 15" width="109.088"
                                                height="109.088" transform="translate(763.613)" fill="#06c4f1" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>