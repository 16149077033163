import { createStore } from "vuex";

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false,

            // Touchkit sizing variables
            scale: 1,
            backgroundWidth: 0,
            appResolution: {},
            maximizedSizeAndOffset: {},

            // Touchkit Page data
            currentBackgroundComponents: [],

            // Touchkit navigation variables
            allowNavigation: true,
            isFullscreen: false,
            contentComponentOpen: false,
            videoTransitions: [],
            queryParameters: null,

            // Touchkit Pagescroller
            draggingBackground: false,
            draggingBackgroundStartPositions: [],

            // Touchkit AudioManager
            muted: false,

            // Login
            isLoggedIn: false,

            // Should hide logo (allows components to have more fine grained control)
            hideLogo: false,
        };
    },
    mutations: {
        ready(state) {
            state.ready = true;
        },
        setScaleValue(state, value) {
            state.scale = value;
        },
        setAppResolutionValue(state, value) {
            state.appResolution = value;
        },
        setMaximizedSizeAndOffsetValue(state, value) {
            state.maximizedSizeAndOffset = value;
        },
        setCurrentBackgroundComponents(state, arr) {
            state.currentBackgroundComponents = arr;
        },
        enableNavigation(state) {
            state.allowNavigation = true;
        },
        disableNavigation(state) {
            state.allowNavigation = false;
        },
        toggleFullscreen(state, bool) {
            state.isFullscreen = bool;
        },
        toggleContentComponentOpen(state, bool) {
            state.contentComponentOpen = bool;
        },
        storeVideoTransitions(state) {
            if (
                !app.api.PageManager.getCustomComponentFromType(
                    "videoTransitions"
                )
            )
                return;

            let umbracoTransitions =
                app.api.PageManager.getCustomComponentFromType(
                    "videoTransitions"
                ).data.transitions;
            let transitions = [];

            transitions = umbracoTransitions.map((transition, i) => {
                transition.id = `transition_${i}`;
                transition.from = transition.from
                    ? app.api.PageManager.getRouteFromID(transition.from)
                    : null;
                transition.to = transition.to
                    ? app.api.PageManager.getRouteFromID(transition.to)
                    : null;
                return transition;
            });

            state.videoTransitions = transitions;
        },
        setQueryParameters(state, value) {
            state.queryParameters = value;
        },

        // Touchkit Pagescroller
        setBackgroundWidth(state, value) {
            state.backgroundWidth = value;
        },
        toggleDraggingBackground(state, bool) {
            state.draggingBackground = bool;
        },
        saveScrollPosition(state, value) {
            const existingIndex =
                state.draggingBackgroundStartPositions.findIndex(
                    (s) => s.route === value.route
                );

            // If the object already exists, replace it at the existing index
            if (existingIndex !== -1) {
                state.draggingBackgroundStartPositions.splice(
                    existingIndex,
                    1,
                    value
                );

                // If it doesn't exist, push the new object
            } else {
                state.draggingBackgroundStartPositions.push(value);
            }
        },

        // Touchkit AudioManager
        toggleMute(state) {
            state.muted = !state.muted;

            if (state.muted) {
                app.api.AudioManager.mute();
            } else {
                app.api.AudioManager.unmute();
            }
        },
        mute(state) {
            state.muted = true;
            app.api.AudioManager.mute();
        },
        unmute(state) {
            state.muted = false;
            app.api.AudioManager.unmute();
        },
        setLoggedIn(state, value) {
            state.isLoggedIn = value;
        },
        setHideLogo(state, val) {
            state.hideLogo = val;
        },
    },
    getters: {
        ready(state) {
            return state.ready;
        },
        getScaleValue(state) {
            return state.scale;
        },
        getAppResolutionValue(state) {
            return state.appResolution;
        },
        getMaximizedSizeAndOffsetValue(state) {
            return state.maximizedSizeAndOffset;
        },
        getCurrentBackgroundComponents(state) {
            return state.currentBackgroundComponents;
        },
        canNavigate(state) {
            return state.allowNavigation;
        },
        isFullscreen(state) {
            return state.isFullscreen;
        },
        getContentComponentOpen(state) {
            return state.contentComponentOpen;
        },
        getVideoTransitions(state) {
            return state.videoTransitions;
        },
        getQueryParameters(state) {
            return state.queryParameters;
        },

        // Touchkit Pagescroller
        getBackgroundWidth(state) {
            return state.backgroundWidth;
        },
        isBackgroundDragged(state) {
            return state.draggingBackground;
        },
        getSavedScrollPosition: (state) => (route) => {
            return state.draggingBackgroundStartPositions.find(
                (s) => s.route === route
            );
        },

        // Touchkit AudioManager
        getIsMuted(state) {
            return state.muted;
        },
        getLoggedIn(state) {
            return state.isLoggedIn;
        },
        getHideLogo(state) {
            return state.hideLogo;
        },
    },
    actions: {
        ready(context) {
            context.commit("ready");
        },
        hideLogo(context) {
            context.commit("setHideLogo", true);
        },
        showLogo(context) {
            context.commit("setHideLogo", false);
        },
    },
});

export default store;
