<template>
    <transition name="fadeoutTransitionVideos">
        <div id="TransitionVideos" v-if="videoQueue.length > 0">
            <video v-for="video in playingVideo" :key="video.id" :poster="video.poster" @ended="removeFromQueue" autoplay playsinline webkit-playsinline muted>
                <source :src="video.src">
            </video>
            <button class="TransitionVideos__skipbutton" v-if="isVideoSkipable" @click="removeFromQueue">SKIP</button>
        </div>
    </transition>
</template>
<script>
export default {
    data() {
        return {
            transitions: [],
            videoQueue: []
        }
    },
    created() {
        this.transitions = this.$store.getters.getVideoTransitions;
    },
    mounted() {
        this.populateVideoQueue(null, app.api.PageManager.getCurrentRoute(), true)
    },
    watch: {
        $route(to, from) {
            let toPath = to.fullPath;
            let fromPath = from.fullPath;
            this.populateVideoQueue(fromPath, toPath, false)
        },
        playingVideo() {
            let queueLength = this.videoQueue.length;
            if(queueLength > 0) {
                this.$store.commit('disableNavigation');
            } else {
                this.$store.commit('enableNavigation');
            }
        }
    },
    methods: {
        populateVideoQueue(from, to, isInitialLoad) {
            for(let i = 0; i < this.transitions.length; i++) {
                if((this.transitions[i].from == from || isInitialLoad) && this.transitions[i].to == to) {
                    let video = {
                        id: this.transitions[i].id,
                        src: app.api.Utils.getMediaPath(this.transitions[i].transitionVideo.src),
                        poster: app.api.Utils.getMediaPath(this.transitions[i].transitionPoster.src),
                        skipable: this.transitions[i].skipable
                    }
                    this.videoQueue.push(video);
                } 
            }
        },
        removeFromQueue() {
            this.videoQueue.shift()
        }
    },
    computed: {
        playingVideo() { 
            let video = [this.videoQueue[0]];
            return video;
        },
        isVideoSkipable() {
            return this.videoQueue[0].skipable
        }
    }
}
</script>

<style lang="scss">
#TransitionVideos {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &>video {
        width: 100%;
        height: 100%;
    }
    
    .TransitionVideos__skipbutton {
        position: absolute;
        right: 60px;
        bottom: 60px;
        z-index: 2;
        font-size: 20px;
        color: #fff;
        border: solid 1px #fff;
        padding: 20px 40px;
        background: #ffffff0e;
        
        &:hover {
            background: #ffffff46;
        }
    }
}

.fadeoutTransitionVideos-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}

.fadeoutTransitionVideos-leave-to {
    opacity: 0;
}
</style>