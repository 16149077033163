<template>
  <section class="NullComponent">
    <transition name="fadeIn-text">
      <span v-if="ready">
        Render Error:
        <i>Unknown component: <strong>"{{type}}"</strong>, has a .vue file been created in the ./component-library directory?</i>
      </span>
    </transition>
  </section>
</template>

<script>
export default {
  props: [
    "type"
  ],
  data() {
    return {
      ready: false
    }
  },
  mounted() {
    this.ready = true
  },
};
</script>

<style lang="scss" scoped>
.NullComponent {
  border: solid 2px red;
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #f4f4f4;
  right: 0;
  bottom: 0;

  strong {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

.fadeIn-text-enter-active {
  transition: opacity 0.2s ease-out;
}

.fadeIn-text-enter-from,
.fadeIn-text-leave-to {
  opacity: 0;
}
</style>