<template>
    <button id="FullscreenButton" v-if="!onHomePage" :class="{ 'FullscreenButton--isFullscreen': isFullscreen }"
        @click="toggleFullscreen" aria-hidden="true">
        <div class="button">

            <img src="@/assets/img/ui/fullscreen.svg" v-if="!isFullscreen">
            <img src="@/assets/img/ui/fullscreen-active.svg" v-else>
        </div>
        <span>Full screen</span>

    </button>
</template>
<script>
export default {
    props: ["onHomePage"],
    methods: {
        toggleFullscreen() {
            app.api.Utils.toggleFullscreen();
        }
    },
    computed: {
        isFullscreen() {
            return this.$store.getters.isFullscreen
        }
    }
}
</script>
<style lang="scss" scoped>
$hotspot-height: 44px;

#FullscreenButton {
    position: absolute;
    left: unset;
    right: 50px;

    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .button {
        z-index: 3;
        display: flex;

        img {
            width: $hotspot-height;
        }
    }

    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        height: $hotspot-height - 6px;
        background: var(--light-grey);
        padding: 0 26px 0 18px;
        margin-left: auto;
        margin-right: -14px;

        z-index: 2;

        border-radius: $hotspot-height 0 0 $hotspot-height;
    }
}
</style>