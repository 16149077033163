const config = {
    name: "Medrina",
    language: "en",
    // used for media sharing
    rootUrl: "https://world.medrina.com",

    mediaFolder: "/media",
    manifest: "assets/json/@lang/manifest.json",
};

// for local dev environment on network
const ipRegex =
    /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$|^(?:[A-Fa-f0-9]{1,4}:){6}:[A-Fa-f0-9]{1,4}$|^(?:[A-Fa-f0-9]{1,4}:){5}(?::[A-Fa-f0-9]{1,4}){1,2}$|^(?:[A-Fa-f0-9]{1,4}:){4}(?::[A-Fa-f0-9]{1,4}){1,3}$|^(?:[A-Fa-f0-9]{1,4}:){3}(?::[A-Fa-f0-9]{1,4}){1,4}$|^(?:[A-Fa-f0-9]{1,4}:){2}(?::[A-Fa-f0-9]{1,4}){1,5}$|^[A-Fa-f0-9]{1,4}:(?::[A-Fa-f0-9]{1,4}){1,6}$|^:(?::[A-Fa-f0-9]{1,4}){1,7}$|^:(?::[A-Fa-f0-9]{1,4}){0,6}:[A-Fa-f0-9]{1,4}$|^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[0-9a-fA-F]{0,4}:){1,8}:?$/;

// LOCAL DEV ENVIRONMENT
if (
    window.location.hostname === "localhost" ||
    ipRegex.test(window.location.hostname) ||
    window.location.hostname === "bs-local.com"
) {
    config.manifest =
        "https://medrinaumbraco.azurewebsites.net/output/event/assets/json/@lang/manifest.json";
    config.mediaFolder = "https://medrinaumbraco.azurewebsites.net/media/";

    // ELECTRON PACKAGE
} else if (window.location.hostname === "") {
    config.mediaFolder = "media/";
}

export default config;
