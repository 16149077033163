<template>
    <div id="Idle">
        <component
            v-if="isIdle"
            :is="getComponentClass(componentData)"
            :properties="componentData.data"
            :slug="componentData.slug"
            :type="componentData.type">
        </component>
    </div>
</template>

<script>
import ComponentRepo from "../../services/ComponentRepo";

export default {
    data() {
        return {
            isIdle: false,
            idleTimer: null,
            idleTimout: 5000
        }
    },
    created() {
        this.getIdleTimeout();
    },
    mounted() {
        window.addEventListener('mousedown', this.resetIdleTimer);
        window.addEventListener('mousemove', this.resetIdleTimer);
        window.addEventListener('keydown', this.resetIdleTimer);
        this.startIdleTimer();
    },
    methods: {
        getIdleTimeout() {
            // Convert the timestamp to a Date object
            const date = new Date(this.idleData.timeBeforeIdle);

            // Extract minutes and seconds
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            this.idleTimout = (minutes * 60 + seconds) * 1000;
        },
        getComponentClass(component) {
            return ComponentRepo.has(component.type)
                ? component.type
                : "NullComponent";
        },
        startIdleTimer() {
            this.idleTimer = setTimeout(() => {
                this.isIdle = true;
            }, this.idleTimout);
        },
        resetIdleTimer() {
            this.isIdle = false;
            clearTimeout(this.idleTimer);
            this.startIdleTimer();
        }
    },
    beforeUnmount() {
        window.removeEventListener('mousedown', this.resetIdleTimer);
        window.removeEventListener('mousemove', this.resetIdleTimer);
        window.removeEventListener('keydown', this.resetIdleTimer);
    },
    computed: {
        idleData() {
            return app.api.PageManager.getCustomComponentFromType("idle").data;
        },
        componentData(){
            if(this.idleData.contentToOpen)
                return app.api.PageManager.getContentComponentFromID(this.idleData.contentToOpen)
        }
    }
}
</script>

<style lang="scss">
#Idle {
    position: absolute;
    z-index: 11;
}
</style>