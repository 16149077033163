<template>
    <nav id="UiLayer" :style="[getMaximizedSizeAndOffset]">
        <Logo v-show="!shouldHideLoge"></Logo>
        <BackButton :class="{ 'fade-when-home': !this.showBackBtn }" v-show="showBackBtn"></BackButton>
        <FullscreenButton></FullscreenButton>

        <div class="bottom-line" v-show="!isComponentOpen">
            {{ globalData?.data?.tagline }}
        </div>
    </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import BackButton from "../ui/BackButton.vue";
import AudioToggle from "../ui/AudioToggle.vue";
import FullscreenButton from "../ui/FullscreenButton.vue";
import ShareButton from '../ui/ShareButton.vue'

export default {
    components: {
        // Ui Components
        Logo,
        Breadcrumbs,
        BackButton,
        AudioToggle,
        FullscreenButton,
        ShareButton
    },
    data() {
        return {
            globalData: undefined
        };
    },
    mounted() {
        this.globalData = app.api.PageManager.getCustomComponentFromType('globalData');
    },
    methods: {},
    computed: {
        onHomePage() {
            this.$route;

            return app.api.PageManager.onHomePage();
        },
        isComponentOpen() {
            this.$route;
            return app.api.PageManager.getIsContentComponentOpen();
        },
        getMaximizedSizeAndOffset() {
            return this.$store.getters.getMaximizedSizeAndOffsetValue;
        },
        showBackBtn() {
            if (this.isComponentOpen) return true;
            if (!this.onHomePage) return true;
            return false;
        },
        shouldHideLoge() {
            if (this.$store.getters.getHideLogo) return true;



            this.$route
            var hasFullscreenComponent = false;

            if (!this.isComponentOpen) return hasFullscreenComponent;


            var fullscreenComponents = [''];
            var openComponents = app.api.PageManager.getOpenContentComponents();
            var i = 0;

            while (i < openComponents.length) {
                if (fullscreenComponents.includes(openComponents[i].type)) {
                    hasFullscreenComponent = true
                }
                i++
            }
            return hasFullscreenComponent;

        }
    }
};
</script>

<style lang="scss">
#UiLayer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;


    button {
        position: absolute;
        left: 50px;
        bottom: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        height: 50px;
        // width: 50px;
        transition-property: opacity, transform;
        transition-duration: 0.1s;
        transition-timing-function: ease;

        span {
            font-weight: bold;
            color: var(--main-color)
        }

        img {
            width: 100%;
        }

        &:hover {
            transform: scale(1.05);
        }
    }

    * {
        pointer-events: all;
    }

    .bottom-line {
        position: absolute;
        height: 50px;

        left: 50px;
        right: 50px;
        bottom: 32px;


        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--tertiary-color);
        font-weight: bold;
        font-size: 28px;

        pointer-events: none;
        z-index: -1;

        &::before {
            content: '';
            position: absolute;
            height: 38px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: var(--main-color);
            width: 100%;
            // height: 100%;
            z-index: -1;
            opacity: .85;
            border-radius: 25px;
        }
    }
}
</style>