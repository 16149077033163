export default class PageManager {
    constructor(app) {
        this.app = app;
    }

    /* --------------------------------- ROUTES --------------------------------- */

    // Return current route
    getCurrentRoute() {
        return window.app.vm.$router.currentRoute._value.path;
    }

    // Navigate to a specific route using the Vue Router
    navigateToRoute(route) {
        return window.app.vm.$router.push(route);
    }

    // SILENTLY navigate to a specific route using the Vue Router
    navigateToRouteSilent(route) {
        return window.app.vm.$router.replace(route);
    }

    // Return route from specific page id
    getRouteFromID(id) {
        return this.getAllPages().find((page) => page.data.id === id)?.route;
    }

    // Compose internal route for supplied Scene ID and Content ID
    // (Compose a route for any content on any scene if you supply a sceneID and/or contentID)
    getRouteFromIDs(sceneID, contentID) {
        let sceneRoute = "";
        let contentSlug = "";

        if (sceneID) sceneRoute = this.getRouteFromID(sceneID);

        if (contentID)
            contentSlug = ":" + this.getContentComponentFromID(contentID).slug;

        return `${
            sceneRoute !== "" ? sceneRoute : this.getCurrentRoute()
        }${contentSlug}`;
    }

    // Return route used in hotspots that lead to scenes using the manifest ID
    getHotspotSceneRoute(pageID) {
        return this.getAllPages().find((page) => page.id === pageID).route;
    }

    // Navigate to specific ID
    navigateToID(id) {
        let route = this.getRouteFromID(id);
        return this.navigateToRoute(route);
    }

    // Navigate one step back using the Vue Router
    navigateBack() {
        window.app.vm.$router.go(-1);
    }

    // Navigate to parent scene
    navigateToParentScene() {
        let newRoute = this.getCurrentRoute().split("/").slice(0, -1).join("/");
        if (newRoute === "") {
            return;
        }
        this.navigateToRoute(newRoute);
    }

    /* --------------------------------- QUERYSTRINGS --------------------------------- */

    // Return current route WITH querystring
    getCurrentRouteWithQueryString() {
        const route = this.getCurrentRoute();
        const queryString = new URLSearchParams(route.query).toString();
        return `${route.path}${queryString ? "?" + queryString : ""}`;
    }

    // Add a specific query parameter with a value to the current route
    addQueryParameter(param, value) {
        const currentRoute = this.getCurrentRoute();
        const { path, query } = currentRoute;
        const newQuery = { ...query, [param]: value };
        const newRoute = { path: path, query: newQuery };
        this.navigateToRoute(newRoute);
    }

    // Remove a specific query parameter from the current route
    removeQueryParameter(param) {
        const currentRoute = this.getCurrentRoute();
        const { path, query } = currentRoute;
        if (query.hasOwnProperty(param)) {
            const newQuery = { ...query };
            delete newQuery[param];
            const newRoute = { path: path, query: newQuery };
            this.navigateToRoute(newRoute);
        }
    }

    /* --------------------------------- PAGES --------------------------------- */

    // Get Page Object from route
    getPage(route) {
        let _route = route[0] === "/" ? route.substr(1) : route;
        let fragments = route.replace(/:.*/, "").split("/").slice(-2);
        let result = {};

        if (fragments[0] === "") {
            result = this.getAllPages().find((page) => page.slug === _route);
        } else {
            result = this.getAllPages().find((p) => {
                return p.route == route.split(":")[0];
            });
        }

        return result ? result : this.getRootPage();
    }

    // Check if Page exists from route
    pageExists(route) {
        let _route = route[0] === "/" ? route.substr(1) : route(route);
        _route = _route.split(":")[0];
        let fragments = _route.split("/").slice(-2);

        if (fragments.length === 1 && fragments[0] !== "") {
            return _route === fragments[0];
        } else {
            let result = this.getAllPages().find((p) => {
                return p.route == route.split(":")[0];
            });
            return !!result;
        }
    }

    // Get all pages
    getAllPages() {
        return this.app.manifest.data.pages;
    }

    // Get all scenes
    getAllScenes() {
        return this.getAllPages().filter((scene) => scene.type === "scene");
    }

    // Get Page data from ID
    getPageFromID(id) {
        return this.getAllPages().find((page) => page.data.id === id);
    }

    // return the Home/Root Page
    getRootPage() {
        // HomePage is first page found without a parent
        // This means the first object in the "pages" array ie. getAllPages()[0].
        return this.getAllPages().find((p) => p.parent === null);
    }

    // Get method to return bool == true if on Home/Root Page
    onHomePage() {
        let route = this.getCurrentRoute();
        route = route.split(":")[0];
        return this.getRootPage().route === route;
    }

    // Function call for returning to Home Page
    navigateToHomePage() {
        // check if a default scene is set
        if (this.app.manifest.data.metadata.defaultScene) {
            return this.navigateToRoute(
                this.getRouteFromID(
                    this.app.manifest.data.metadata.defaultScene
                )
            );
        } else {
            return this.navigateToRoute(`/${this.getRootPage().slug}`);
        }
    }

    /* --------------------------------- CONTENT COMPONENTS --------------------------------- */

    getContentComponents() {
        return this.app.manifest.data.content;
    }

    // Open content from explicit ID (Base Content)
    openContentComponentFromID(id, singleton) {
        // Singleton is a bool, when true, closes all other content components instead of opening it on top.
        let route = this.getCurrentRoute();
        let contentSlug = "";

        // Check to see if content exists in manifest before going to manifest.data.content
        if (this.getContentComponentExistsInManifest(id)) {
            contentSlug = this.getContentComponentsInManifest().find(
                (c) => c.data.id === id
            ).slug;
        } else {
            contentSlug = this.getContentComponentFromID(id).slug;
        }

        // Check to see if this component should close all other content components
        if (singleton === true) route = route.split(":")[0];

        // Check to see if Content Component is already open
        if (!route.includes(contentSlug))
            this.navigateToRoute(route + ":" + contentSlug);
    }

    // Look through getAllPages() and only return Content Components
    getContentComponentsInManifest() {
        return this.getAllPages().filter((page) => page.type == "content");
    }

    // Get if Content Component exists in getAllPages() from given slug
    getContentComponentExistsInManifest(id) {
        return this.getContentComponentsInManifest().some(
            (component) => component.data.id === id
        );
    }

    // Get Content Component data from given slug
    getContentComponent(slug) {
        return this.getContentComponents().find(
            (component) => component.slug === slug
        );
    }

    // Get Content Component data from given content ID
    getContentComponentFromID(id) {
        return this.getContentComponents().find(
            (component) => component.id === id
        );
    }

    // Get all Content Components from a specific type
    getContentComponentsFromType(type) {
        return this.getContentComponents().filter(
            (component) => component.type === type
        );
    }

    // Get Content Component data from given slug specifically when referenced from manifest
    getContentComponentInManifest(slug) {
        return this.getContentComponentsInManifest().find(
            (component) => component.slug === slug
        );
    }

    // Return if any content component is open by looking at the route.
    getIsContentComponentOpen() {
        return this.getCurrentRoute().includes(":");
    }

    // Return currently open content componets by looking at the route
    getOpenContentComponents() {
        let contentComponents = [];

        if (!this.getIsContentComponentOpen()) return contentComponents;

        let route = this.getCurrentRoute().split(":");
        route.shift(0);

        let i = 0;
        while (i < route.length) {
            let component = this.getAllPages().find(
                (page) => page.slug == route[i]
            );
            if (component) contentComponents.push(component.data);
            i++;
        }

        if (route.length > contentComponents.length) {
            i = 0;
            while (i < route.length) {
                let component = this.getContentComponent(route[i]);
                if (component && !contentComponents.includes(component))
                    contentComponents.push(component);
                i++;
            }
        }

        return contentComponents;
    }

    // Close all currently open Content Components
    closeAllContentComponents() {
        let route = this.getCurrentRoute().split(":")[0];
        this.navigateToRoute(route);
    }

    // Close all currently open Content Components
    closeContentComponent(slug) {
        let regex = new RegExp(`:${slug}\\b`, "g");
        let route = this.getCurrentRoute().replace(regex, "");
        this.navigateToRoute(route);
    }

    /* --------------------------------- CUSTOM CONTENT --------------------------------- */

    // Get all Custom Data
    getCustomData() {
        return this.app.manifest.data.custom;
    }

    // Return data for specific type of custom data
    getCustomComponentFromType(type) {
        return this.getCustomData().find((c) => c.type === type);
    }

    /* --------------------------------- HOTSPOTS --------------------------------- */

    // return data from a specific hotspot's id (not its scene or compoenent reference)
    getHotspotDataFromContentID(id) {
        return this.getAllPages().find((c) => c.id == id);
    }

    // return data from a specific component's id
    getComponentDataFromHotspotID(id) {
        return this.getContentComponentsInManifest().find((c) => c.id == id)
            .data;
    }

    // return data from a specific id, the slug of the corresponding component
    getComponentSlugFromHotspotID(id) {
        return this.getContentComponentsInManifest().find((c) => c.id == id)
            .slug;
    }

    /* --------------------------------- BREADCRUMBS --------------------------------- */

    // Get all Breadcrumbs by evaluating the CurrentRoute() and using the Title and Slug
    getBreadcrumbs() {
        let breadcrumbs = [];
        let manifestPages = this.getAllPages();
        let tempRoute = this.getCurrentRoute()
            .split(/[\/:]/)
            .filter((el) => el != "" || null);

        breadcrumbs = tempRoute.map((breadcrumb) => {
            let page = manifestPages.find((page) => page.slug === breadcrumb);
            if (page !== undefined) {
                return {
                    title: page.title,
                    slug: breadcrumb,
                    route: page.route,
                    type: page.type,
                };
            } else {
                return;
            }
        });

        return breadcrumbs;
    }
}
