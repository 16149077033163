<template>
    <article class="imageCarouselTemplate">
        <Carousel :activeIndex="activeIndex">
            <img :src="getMediaPath(image.src)" v-for="image in properties?.images">
        </Carousel>
        <div class="controls">
            <button class='nav-button' @click="decrementActiveIndex" :class="this.activeIndex === 0 && 'inactive'">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.243" height="38.485" viewBox="0 0 22.243 38.485">
                    <path id="_" data-name="&lt;" d="M240.595,656l-15,15,15,15" transform="translate(-222.595 -651.757)"
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" />
                </svg>

            </button>
            <button class='nav-button' @click="incrementActiveIndex"
                :class="this.activeIndex >= this.properties?.images?.length - 1 && 'inactive'">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.242" height="38.485" viewBox="0 0 22.242 38.485">
                    <path id="_" data-name="&lt;" d="M225.595,656l15,15-15,15" transform="translate(-221.352 -651.757)"
                        fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" />
                </svg>
            </button>
        </div>
        <div class="nav-dots">
            <button class="dot" v-for="dot, index in properties.images.length"
                :class="index === activeIndex ? 'active' : ''" @click="activeIndex = index"></button>
        </div>
        <ShareButton :media="properties.images"></ShareButton>

    </article>
</template>

<script>
import ShareButton from '../../ui/ShareButton.vue'

export default {
    props: ['properties', 'slug'],
    components: {
        ShareButton
    },
    data() {
        return {
            activeIndex: 0,
        }
    },
    mounted() {
        console.log(this.properties);
    },
    methods: {
        getMediaPath(src) {
            return app.api.Utils.getMediaPath(src)
        },
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        },
        incrementActiveIndex() {
            if (this.activeIndex >= this.properties?.images?.length - 1) return;
            this.activeIndex++;
        },
        decrementActiveIndex() {
            if (this.activeIndex === 0) return;
            this.activeIndex--;
        },
    }
}
</script>

<style lang="scss" scoped>
.imageCarouselTemplate {
    width: 100%;
    height: 100%;
    position: relative;
}

img {
    object-fit: cover;
    min-width: 100%;
}

.controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;

    pointer-events: none;
}

.nav-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: var(--main-color);
    pointer-events: all;

    display: flex;
    align-items: center;
    justify-content: center;


    transition: transform .2s ease;

    &:hover {
        transform: scale(1.05);
    }
}

.nav-dots {
    position: absolute;
    bottom: 42px;

    width: 100%;
    justify-content: center;


    display: flex;
    gap: 20px;
}

.dot {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3.5px solid white;
    transition: all .25 ease;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        border-radius: 50%;
        background-color: var(--main-color);
        opacity: .3;

        transition: all .25s ease;

    }

    &.active {
        &::before {
            opacity: 1;
            background-color: white;
        }
    }

    transition: transform .2s ease;

    &:hover {
        transform: scale(1.05);
    }
}

.inactive {
    opacity: .2;
    pointer-events: none;
}
</style>