<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route"
            :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]" :style="[getPosition]"
            @click="hotspotPressed">
            <div class="Hotspot__button" :style="[getAlternateHotspotIcon, getContinuousHotspotAnimation]"></div>
            <span v-html="getHotspotTitle"></span>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"

            switch (this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel`

                // more types can be added here through the backend hotspotType data value.

                default:
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if (this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getAlternateHotspotIcon() {
            if (this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        },
        getContinuousHotspotAnimation() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            return {
                'animation-name': "hotspotLoopPulse",
                'animation-iteration-count': 'infinite',
                'animation-duration': '15s',
                'animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 44px;

.Hotspot {
    display: flex;
    align-items: center;

    .Hotspot__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        background-image: url(@/assets/img/ui/default-hotspot.svg);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        // border: 2px solid var(--light-grey);
        border-radius: 50%;
        z-index: 3;
    }

    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        font-weight: 500;
        color: white;
        font-size: 20px;
        height: $hotspot-height - 6px;
        background-color: var(--main-color);
        padding: 0 22px 0 32px;
        margin-left: -14px;
        box-shadow: var(--shadow-small);
        z-index: 2;

        border-radius: 0 $hotspot-height $hotspot-height 0;

        transition-property: color, background-color;
        transition-duration: .2s;
        transition-timing-function: ease;
    }

    &:hover {
        span {
            background-color: #eee;
            color: var(--main-color);

        }
    }

    &:active {
        span {
            background: #ddd;
        }
    }

    &--flipped {
        flex-direction: row-reverse;

        span {
            padding: 0 32px 0 22px;
            margin-left: auto;
            margin-right: -14px;

            border-radius: $hotspot-height 0 0 $hotspot-height;
        }
    }

    // Styles for specifc components that hotspot can open
    &--fullscreenVideo,
    &--fullscreenIframePlayer {
        .Hotspot__button {
            // background-image: url(@/assets/img/ui/play.svg);
        }
    }

    &--externalLink {
        .Hotspot__button {
            // background-image: url(@/assets/img/ui/globe.svg);
        }
    }

    &--mail {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/mail.svg);
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        span {
            display: none;
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}

.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}

.HotspotEntrance-enter-from {
    transform: translateY(-300%);
    opacity: 0;
}

.HotspotEntrance-leave-to {
    opacity: 0;
}


/* default*/
@keyframes hotspotLoopPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        transform: scale(1.3);
    }

    7% {
        box-shadow: 0 0 0 120px rgba(255, 255, 255, 0);
        transform: scale(1.05);
    }

    10% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }
}
</style>