export default function AppInitializer() {
    const steps = [];

    return Object.freeze({
        start,
        addStep,
    })

    function addStep({ id, tasks = [] }) {
        steps.push({ id, tasks });
        return this;
    }

    async function start(beforeStart = () => { }) {
        beforeStart();

        var i = 0
        var totalSteps = 0
        steps.forEach(individualStep => totalSteps += individualStep.tasks.length);
        
        for (const step of steps) {
            await Promise.all(step.tasks.map(e => {
                console.log('App initializing:\n =>', e.id);
                const promise = e.execute()
                promise.then(() => {
                    console.log('Completed initializing:\n =>', e.id);
                    i++
                    document.getElementById("AppLoader-Percentage").innerHTML = `${(i / totalSteps) * 100}%`
                    document.getElementById("AppLoader-Text").innerHTML = `Loading (${e.id})`
                }).catch(e => {
                    console.log('Failed initializing:\n =>', e.id);
                })
                return promise;
            }))
        }
    }
}