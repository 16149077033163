<template>
    <article class="videoAndTextTemplate  main-background">
        <div class="fullscreenVideo" :class="{ 'fullscreenVideo--videopaused': isVideoPaused }">
            <video type="video/mp4" crossorigin autoplay controls @playing="isVideoPaused = false"
                @pause="isVideoPaused = true;">
                <source :src="getMediaPath">
            </video>
        </div>
        <div class="text-container">
            <h2>{{ properties?.title }}</h2>
            <div class="scroll-container" v-html="properties?.description"></div>
        </div>
        <ShareButton :media="properties.video"></ShareButton>

    </article>
</template>

<script>
import ShareButton from '../../ui/ShareButton.vue'

export default {
    props: ['properties', 'slug'],
    data() {
        return {
            isVideoPaused: true
        }
    },
    components: {
        ShareButton
    },
    mounted() {
        console.log(this.properties);
    },
    methods: {
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        }
    },
    computed: {
        getMediaPath() {
            return app.api.Utils.getMediaPath(this.properties.video.src)
        },
    }
}
</script>

<style lang="scss"> .videoAndTextTemplate {
     p {
         font-size: 22px;
     }

     li {
         font-size: 22px;
     }
 }
</style>
<style lang="scss" scoped>
.videoAndTextTemplate {
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
}

.fullscreenVideo {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 150px;
        height: 150px;
        border-radius: 75px;
        pointer-events: none;
        background-image: url(@/assets/img/ui/play.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        z-index: 3;
    }


    &--videopaused {
        video {
            cursor: pointer;
        }

        &::after,
        &::before {
            content: '';
        }
    }
}

video {
    width: 100%;
}


.text-container {
    background-color: rgba($color: #000000, $alpha: .3);

    color: white;
    font-size: 32px;

    text-align: start;
    padding: 60px 40px;

    overflow: hidden;
}

.scroll-container {
    height: 75%;

    overflow-y: auto;
    padding-right: 34px;
}

h2 {
    margin-bottom: 22px;
    font-size: 45px;
}
</style>