<template>
    <button id="BackButton" @click="navigateBack" aria-label="back">
        <div class="button">
            <img src="@/assets/img/ui/arrow-left.svg">
        </div>
        <span>Back</span>
    </button>
</template>
<script>
export default {
    methods: {
        navigateBack() {
            if (app.api.PageManager.getIsContentComponentOpen()) app.api.PageManager.closeAllContentComponents();
            else app.api.PageManager.navigateToParentScene();
        }
    },

}
</script>
<style lang="scss" scoped>
$hotspot-height: 44px;

#BackButton {
    left: 50px;
    display: flex;
    align-items: center;

    .button {
        width: 100%;
        z-index: 3;
        display: flex;

        img {
            width: $hotspot-height;
        }
    }

    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        height: $hotspot-height - 6px;
        background: var(--light-grey);
        padding: 0 18px 0 26px;
        margin-left: -14px;
        z-index: 2;

        border-radius: 0 $hotspot-height $hotspot-height 0;
    }
}

.Backbutton--inactive {
    opacity: 0;
    pointer-events: none !important;
}
</style>